<template>
  <div class="page-container">
    <div class="page-header">
      <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item v-for="(item, index) in breadcrumb" :key="index">
          <a v-if="item.path" @click.prevent="gotoPage(item.path)">{{item.name}}</a>
          <span v-else>{{item.name}}</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
      <!-- <h2 class="page-title">{{title}}</h2> -->
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumb: {
      default: () => ([])
    },
    title: {
      default: ''
    }
  },
  methods: {
    gotoPage(name) {
      this.$router.push({
        name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-container{
    height: 100%;
  }
.page-header {
  // height: 100px;
  background-color: white;
  padding: 10px 20px;
}
.page-title {
  margin-top: 10px;
  margin-bottom: 0;
}
</style>