import { getItemFromArrayByKey } from 'U'

export default {
  namespaced: true,
  state: {
    eventRecordId:'',
    codeRescue:'',
    unitId:'',
    collapsed: false,
    selectedKeys: [],
    tabs: [{
      id: 'home',
      name: '首页',
      path: 'home'
    }],
    selectedTab: '',
    fullScreen: false,
    wlwDate: null,
    areacode: "430181",
    fontSize: 14
  },
  mutations: {
    setEventRecordId(state,eventRecordId){
      state.eventRecordId = eventRecordId
    },
    setUnitId(state,unitId){
      state.unitId = unitId
    },
    setCodeRescue(state,codeRescue){
      state.codeRescue=codeRescue
    },
    setCollapsed(state, collapsed) {
      state.collapsed = collapsed;
    },
    setSelectedKeys(state, selectedKeys) {
      state.selectedKeys = selectedKeys;
    },
    resetTabs(state, tabs) {
      state.tabs = [{
        id: 'home',
        name: '首页',
        path: 'home'
      }];
    },
    addTab(state, tab) {
      state.tabs.push(tab);
    },
    delTab(state, id) {
      state.tabs = state.tabs.filter(item => item.id !== id);
    },
    setSelectedTab(state, selectedTab) {
      state.selectedTab = selectedTab;
    },
    setFullScreen(state, bol) {
      state.fullScreen = bol
    },
    setWlwBigdate(state, date) {
      state.wlwDate = date
    },
    setFontSize(state, size) {
      state.fontSize = size
    }
  },
  actions: {
    addTab({ state, commit, rootState }, id) {
      let flag = true;
      for (let i = 0; i < state.tabs.length; i++) {
        if (state.tabs[i].id == id) {
          flag = false;
          break;
        }
      }
      if (flag) {
        let name = '';
        let otherPages = { userCenter: '个人中心' };
        let menuItem = getItemFromArrayByKey(rootState.menuList, 'menuurl', id);
        if (menuItem) {
          name = menuItem.menuname
          commit('addTab', {
            id,
            name,
            path: id
          })
        } else if (otherPages[id]) {
          name = otherPages[id]
          commit('addTab', {
            id,
            name,
            path: id
          })
        }
      }
    },
  }
}