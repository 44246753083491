import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 全局样式
import './assets/css/common.scss'
// ant-design-vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// 覆盖ant-design默认样式
import './assets/css/antdesign.scss'
import './assets/font/stylesheet.css'
Vue.use(Antd);
import { Message } from 'element-ui'
let MessageClass = class {
  constructor(message) {
    // this.info(message);
  }
  info(message) {
    Message({
      showClose: true,
      message: message,
      type: 'info'
    })
  }
  warning(message) {
    Message({
      showClose: true,
      message: message,
      type: 'warning'
    })
  }
  error(message) {
    Message({
      showClose: true,
      message: message,
      type: 'error'
    })
  }
  success(message) {
    Message({
      showClose: true,
      message: message,
      type: 'success'
    })
  }
}
Vue.prototype.$message = new MessageClass();
// 全局mixins
import './mixins'
// 全局方法
import './utils/common'
// websocket
import WS from './utils/ws'
Vue.prototype.$ws = new WS();
//echarts
var echarts = require('echarts');
Vue.prototype.echarts = echarts
// 全局组件
import './components'
// mockjs
if (store.state.mock && process.env.NODE_ENV == 'development') {
  require('./mock')
}

Vue.config.productionTip = false


import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')