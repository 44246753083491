export default [
  {
    menuid: 'jcgn',
    menuurl: '',
    menuname: '基础功能',
    menupic: 'appstore',
    children: [
      {
        menuid: 'elevatorArchives',
        menuurl: 'elevatorArchives',
        menuname: '电梯档案'
      },
      {
        menuid: 'userDept',
        menuurl: 'userDept',
        menuname: '维护单位'
      },
    ]
  },
  {
    menuid: 'xtpz',
    menuurl: '',
    menuname: '系统配置',
    menupic: 'setting',
    children: [
      {
        menuid: 'yhgl',
        menuurl: '',
        menuname: '用户管理',
        children: [
          {
            menuid: 'peopleManagement',
            menuurl: 'peopleManagement',
            menuname: '人员管理',
          },
          {
            menuid: 'maintenanceGroupManagement',
            menuurl: 'maintenanceGroupManagement',
            menuname: '维保组管理',
          },
        ]
      },
      {
        menuid: 'roleManagement',
        menuurl: 'roleManagement',
        menuname: '角色管理'
      },
      {
        menuid: 'menuManagement',
        menuurl: 'menuManagement',
        menuname: '菜单管理'
      },
      {
        menuid: 'logManagement',
        menuurl: 'logManagement',
        menuname: '日志管理'
      },
    ]
  },
  {
    menuid: 'm1',
    menuurl: '',
    menuname: '应急处置',
    menupic: 'warning',
    children: [
      {
        menuid: 'emergency',
        menuurl: 'emergency',
        menuname: '应急处置'
      },
      {
        menuid: 'emergencyHistory',
        menuurl: 'emergencyHistory',
        menuname: '应急处置历史'
      },
      {
        menuid: 'modify',
        menuurl: 'modify',
        menuname: '一键修改'
      },
    ]
  },
  {
    menuid: 'm2',
    menuurl: '',
    menuname: '物联网监测',
    menupic: 'global',
    children: [
      {
        menuid: 'timeMonitoring',
        menuurl: 'timeMonitoring',
        menuname: '实时监控'
      },
      {
        menuid: 'webthingsAlarm',
        menuurl: 'webthingsAlarm',
        menuname: '物联网报警'
      },
      {
        menuid: 'monitoringEquipment',
        menuurl: 'monitoringEquipment',
        menuname: '监测设备管理'
      },
    ]
  },
  {
    menuid: 'm3',
    menuurl: '',
    menuname: 'AI智能识别',
    menupic: 'robot',
    children: [
      {
        menuid: 'AIdiscern',
        menuurl: 'AIdiscern',
        menuname: 'AI智能识别'
      },
    ]
  },
]