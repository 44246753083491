import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getCache, getAllCache, getItemFromArrayByKey } from '@/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Index.vue')
  },
  {
    path: '/',
    name: 'main',
    component: () => import('../views/main/Index.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      // 系统配置
      {
        path: '/userCenter',
        name: 'userCenter',
        component: () => import('../views/xtpz/userCenter.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/peopleManagement',
        name: 'peopleManagement',
        component: () => import('../views/xtpz/yhgl/peopleManagement.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/peopleDistributed',
        name: 'peopleDistributed',
        component: () => import('../views/xtpz/yhgl/peopleDistributed.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/maintenanceGroupManagement',
        name: 'maintenanceGroupManagement',
        component: () => import('../views/xtpz/yhgl/maintenanceGroupManagement.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/checkoutGroupManagement',
        name: 'checkoutGroupManagement',
        component: () => import('../views/xtpz/yhgl/checkoutGroupManagement.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/detectionGroupManagement',
        name: 'detectionGroupManagement',
        component: () => import('../views/xtpz/yhgl/detectionGroupManagement.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/roleManagement',
        name: 'roleManagement',
        component: () => import('../views/xtpz/roleManagement.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/menuManagement',
        name: 'menuManagement',
        component: () => import('../views/xtpz/menuManagement.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/logManagement',
        name: 'logManagement',
        component: () => import('../views/xtpz/logManagement.vue'),
        meta: {
          // keepAlive: true
        }
      },
      // 基础功能
      {
        path: '/elevatorProperties',
        name: 'elevatorProperties',
        component: () => import('../views/jcgn/elevatorProperties/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorProperties1',
        name: 'elevatorProperties1',
        component: () => import('../views/jcgn/elevatorProperties/Index1.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorProperties2',
        name: 'elevatorProperties2',
        component: () => import('../views/jcgn/elevatorProperties/Index2.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorProperties3',
        name: 'elevatorProperties3',
        component: () => import('../views/jcgn/elevatorProperties/Index3.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorProperties4',
        name: 'elevatorProperties4',
        component: () => import('../views/jcgn/elevatorProperties/Index4.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorProperties5',
        name: 'elevatorProperties5',
        component: () => import('../views/jcgn/elevatorProperties/Index5.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorProperties6',
        name: 'elevatorProperties6',
        component: () => import('../views/jcgn/elevatorProperties/Index6.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorProperties7',
        name: 'elevatorProperties7',
        component: () => import('../views/jcgn/elevatorProperties/Index7.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorProperties8',
        name: 'elevatorProperties8',
        component: () => import('../views/jcgn/elevatorProperties/Index8.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorDraft',
        name: 'elevatorDraft',
        component: () => import('../views/jcgn/elevatorDraft/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/elevatorArchives',
        name: 'elevatorArchives',
        component: () => import('../views/jcgn/elevatorArchives/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/elevatorData',
        name: 'elevatorData',
        component: () => import('../views/jcgn/elevatorData/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/elevatorArchivesDetail',
        name: 'elevatorArchivesDetail',
        component: () => import('../views/jcgn/elevatorArchives/DetailPage.vue'),
      },
      {
        path: '/timeMonitoring',
        name: 'timeMonitoring',
        component: () => import('../views/jcgn/elevatorArchives/timeMonitoring/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorCheck',
        name: 'elevatorCheck',
        component: () => import('../views/jcgn/elevatorCheck/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/upgradeLog',
        name: 'upgradeLog',
        component: () => import('../views/ai/monitoringEquipment/upgradeLog/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/userDept',
        name: 'userDept',
        component: () => import('../views/jcgn/userDept/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/adminDivision',
        name: 'adminDivision',
        component: () => import('../views/jcgn/adminDivision/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/elevatorMap',
        name: 'elevatorMap',
        component: () => import('../views/jcgn/elevatorMap/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      // 维修管理
      {
        path: '/repairTask',
        name: 'repairTask',
        component: () => import('../views/wbgl/repairTask/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/repairItem',
        name: 'repairItem',
        component: () => import('../views/wbgl/repairItem/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      // 保养管理
      {
        path: '/maintenanceSchedule',
        name: 'maintenanceSchedule',
        component: () => import('../views/wbgl/maintenanceSchedule/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/maintenanceTask',
        name: 'maintenanceTask',
        component: () => import('../views/wbgl/maintenanceTask/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/maintenanceItem',
        name: 'maintenanceItem',
        component: () => import('../views/wbgl/maintenanceItem/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      // 物联网故障管理
      {
        path: '/IoTFailure',
        name: 'IoTFailure',
        component: () => import('../views/wlwbj/wlwMalfunction/Index.vue'),
        meta: {
          // keepAlive: true
        }
      }, {
        path: '/IoTAlarm',
        name: 'IoTAlarm',
        component: () => import('../views/wlwbj/wlwAlarm/Index.vue'),
        meta: {
          // keepAlive: true
        }
      }, {
        path: '/IoTEvent',
        name: 'IoTEvent',
        component: () => import('../views/wlwbj/wlwEvent/Index.vue'),
        meta: {
          // keepAlive: true
        }
      }, {
        path: '/elevatorEvent',
        name: 'elevatorEvent',
        component: () => import('../views/wlwbj/elevatorEvent/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      //电瓶车事件解除
      {
        path:'/elecarEvent',
        name:'elecarEvent',
        component: () => import('../views/xtpz/elecarEvent/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      //投诉与建议历史
      {
        path: '/complaint',
        name: 'complaint',
        component: () => import('../views/complaint/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      //应急救援
      {
        path: '/emergency',
        name: 'emergency',
        component: () => import('../views/yjcz/emergency/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
        //一键修改
      {
        path: '/modify',
        name: 'modify',
        component: () => import('../views/yjcz/emergency/modify.vue'),
        meta: {
          // keepAlive: true
        }
      },
      //电梯困人历史
      {
        path: '/tiringhistory',
        name: 'tiringhistory',
        component: () => import('../views/yjcz/emhistory/tiringhistory.vue'),
        meta: {
          // keepAlive: true
        }
      },
      //电梯故障历史
      {
        path: '/faulthistory',
        name: 'faulthistory',
        component: () => import('../views/yjcz/emhistory/faulthistory.vue'),
        meta: {
          // keepAlive: true
        }
      },
      //打错电话
      {
        path: '/wrongcall',
        name: 'wrongcall',
        component: () => import('../views/yjcz/emhistory/wronghistory.vue')
      },
      //恶意骚扰
      {
        path: '/malicious',
        name: 'malicious',
        component: () => import('../views/yjcz/emhistory/malicioushistory.vue')
      },
      //咨询历史
      {
        path: '/consultation',
        name: 'consultation',
        component: () => import('../views/yjcz/emhistory/consulehistory.vue')
      },
      //其他历史
      {
        path: '/otherhistory',
        name: 'otherhistory',
        component: () => import('../views/yjcz/emhistory/otherhistory.vue'),
        meta: {
          // keepAlive: true
        }
      },
      //生产管理
      {
        path: '/promanager',
        name: 'promanager',
        component: () => import('../views/ai/monitoringEquipment/proManager/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      //异常码流
      {
        path: '/abnormalStream',
        name: 'abnormalStream',
        component: () => import('../views/ai/monitoringEquipment/abnormalStream/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
        //远程管理
      {
        path: '/remoteManagement',
        name: 'remoteManagement',
        component: () => import('../views/tysz/remoteManagement'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/terminalEvent',
        name: 'terminalEvent',
        component: () => import('../views/wlwbj/terminalEvent/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/IoTCodeEntry',
        name: 'IoTCodeEntry',
        component: () => import('../views/wlwbj/codeEntry/Index.vue'),
        meta: {
          // keepAlive: true
        }
      }, {
        path: '/codeItems',
        name: 'codeItems',
        component: () => import('../views/wlwbj/codeItems/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/enquipmentList',
        name: 'enquipmentList',
        component: () => import('../views/ai/monitoringEquipment/enquipmentList/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/devicetypeList',
        name: 'devicetypeList',
        component: () => import('../views/ai/monitoringEquipment/devicetypeList/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/equipmentGroup',
        name: 'equipmentGroup',
        component: () => import('../views/ai/monitoringEquipment/equipmentGroup/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/recoverLog',
        name: 'recoverLog',
        component: () => import('../views/ai/monitoringEquipment/recoverLog/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/paramsList',
        name: 'paramsList',
        component: () => import('../views/ai/monitoringEquipment/paramsTreeManager/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/paramsConfig',
        name: 'paramsConfig',
        component: () => import('../views/ai/monitoringEquipment/paramsTreeManager/paramsConfig.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/paramsConfigs',
        name: 'paramsConfigs',
        component: () => import('../views/ai/monitoringEquipment/equipmentGroup/paramsConfig.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/softManagement',
        name: 'softManagement',
        component: () => import('../views/ai/monitoringEquipment/softManagement/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/paramsConfigHistory',
        name: 'paramsConfigHistory',
        component: () => import('../views/ai/monitoringEquipment/paramsConfigHistory/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/paramsBatchHistory',
        name: 'paramsBatchHistory',
        component: () => import('../views/ai/monitoringEquipment/paramsBatchHistory/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/maintenanceStatistic',
        name: 'maintenanceStatistic',
        component: () => import('../views/dsjfzjc/maintenanceStatistic/statistic/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/malfunctionStatistic',
        name: 'malfunctionStatistic',
        component: () => import('../views/dsjfzjc/aiNetwork/fault/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/eventStatistic',
        name: 'eventStatistic',
        component: () => import('../views/dsjfzjc/aiNetwork/event/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/alarm',
        name: 'alarm',
        component: () => import('../views/dsjfzjc/aiNetwork/alarm/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/liftevent',
        name: 'liftevent',
        component: () => import('../views/dsjfzjc/aiNetwork/liftevent/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/tiring',
        name: 'tiring',
        component: () => import('../views/dsjfzjc/aiNetwork/tiring/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/getReport',
        name: 'getReport',
        component: () => import('../views/dsjfzjc/aiNetwork/report/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/rescueStatistic',
        name: 'rescueStatistic',
        component: () => import('../views/dsjfzjc/rescueStatistic/rescue/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/maintenanceReport',
        name: 'maintenanceReport',
        component: () => import('../views/dsjfzjc/maintenanceStatistic/report/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/rescueReport',
        name: 'rescueReport',
        component: () => import('../views/dsjfzjc/rescueStatistic/report/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
        //电梯运行数据统计
      {
        path: '/rundata',
        name: 'rundata',
        component: () => import('../views/dsjfzjc/rundataStatistic/rundata/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/checklogList',
        name: 'checklogList',
        component: () => import('../views/ai/monitoringEquipment/checklogList/Index.vue'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/lotBigData',
        name: 'lotBigData',
        component: () => import('../views/lotBigData/main/Index.vue')
      },
      //大数据视图页面
      {
        path: '/superviseBigData',
        name: 'superviseBigData',
        component: () => import('../views/superviseBigData/main/Index.vue')
      },
        //平台使能参数
      {
        path: '/paramset',
        name: 'paramset',
        component: () => import('../views/tysz/paramset.vue')
      },
      //平台功能参数
      {
        path: '/functionparams',
        name: 'functionparams',
        component: () => import('../views/tysz/functionParams.vue')
      },
        //终端高级参数
      {
        path: '/advanceparam',
        name: 'advanceparam',
        component: () => import('../views/tysz/advanceparam.vue')
      },
        //测试页面
      {
        path: '/xlab',
        name: 'xlab',
        component: () => import('V/testpage/test.vue')
      },
      //平台自检日志
      {
        path: '/selfTestLog',
        name: 'selfTestLog',
        component: () => import('V/ai/monitoringEquipment/selfTest/platSelfTest/selfTestLog.vue')
      },
      //平台自检异常日志
      {
        path: '/abnormalLog',
        name: 'abnormalLog',
        component: () => import('V/ai/monitoringEquipment/selfTest/platSelfTest/abnormalLog.vue')
      },
      //终端自检日志
      {
        path: '/devSelfTestLog',
        name: 'devSelfTestLog',
        component: () => import('V/ai/monitoringEquipment/selfTest/devSelfTest/devSelfTestLog.vue')
      },
      //终端自检异常日志
      {
        path: '/devAbnormalLog',
        name: 'devAbnormalLog',
        component: () => import('V/ai/monitoringEquipment/selfTest/devSelfTest/devAbnormalLog.vue')
      },
      //TF卡异常
      {
        path: '/tfAbnormal',
        name: 'tfAbnormal',
        component: () => import('V/ai/monitoringEquipment/selfTest/devSelfTest/tfAbnormal.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //按需维保单位资格申请
      {
        path: '/maintaindepappc',
        name: 'maintaindepappc',
        component: () => import('V/backlogs/maintainmanage/maintaindepappc/Index')
      },
      //按需维保项目资格申请
      {
        path: '/maintainproappc',
        name: 'maintainproappc',
        component: () => import('V/backlogs/maintainmanage/maintainproappc/Index.vue')
      },
      //问题整改
      {
        path: '/abarbeitung',
        name: 'abarbeitung',
        component: () => import('V/backlogs/maintainmanage/abarbeitung/Index.vue')
      },
      //电梯检测单位资格申请
      {
        path: '/detectiondepappc',
        name: 'detectiondepappc',
        component: () => import('V/backlogs/detectionmanage/detectiondepappc/Index.vue')
      },
      //电梯检测项目资格申请
      {
        path: '/detectionproappc',
        name: 'detectionproappc',
        component: () => import('V/backlogs/detectionmanage/detectionproappc/Index.vue')
      },
      //电梯检验任务
      {
        path: '/checktasks',
        name: 'checktasks',
        component: () => import('V/checkanddetect/checkout/checktasks/Index.vue')
      },
      //电梯检验条目
      {
        path: '/checkitems',
        name: 'checkitems',
        component: () => import('V/checkanddetect/checkout/checkitems/Index.vue')
      },
      //关于本系统
      {
        path: '/aboutSystem',
        name: 'aboutSystem',
        component: () => import('V/xtpz/aboutSystem.vue')
      },
    ]
  },
]

import Router from 'vue-router';
Vue.use(Router)
//路由导航冗余报错（路由重复）
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
  // const userAccount = store.state.userInfo?store.state.userInfo.useraccount:""
  // const menuRole = store.state.menuList; // 从 Vuex 存储中获取用户角色，假设已经登录并存储了用户角色信息
  // const hasRole = findValueInTree(menuRole,to.name,userAccount)
  // if (!hasRole) {
  //   // 如果该路由需要认证且用户没有相应的角色，重定向到登录页面或错误页面
  //   next('/login'); // 登录页面路径或错误页面路径
  // }
  if (store.state.token || to.path == '/login') {
    gotoPage(to, from, next)
  } else {
    if (getCache('token')) {
      let cacheDatas = getAllCache();
      store.commit('setCacheDatas', cacheDatas);
      // gotoPage(to, from, next);
      // 刷新页面回到首页
      next('/')
    } else {
      next("/login");
    }
  }
})

const gotoPage = (to, from, next) => {
  // 回到页面顶部
  try {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  } catch (e) {
    document.body.scrollTop = 0;
  }
  // 路由参数缓存
  let params = to.params;
  if (params) {
    if (Object.keys(params).length) {
      store.commit('setPageParams', {
        path: to.path,
        params: params
      });
    } else if (store.state.paramsMap[to.path]) {
      params = store.state.paramsMap[to.path];
      for (let key in params) {
        to.params[key] = params[key];
      }
    }
  }
  next();
}

router.afterEach((to, from) => {
  // 当前菜单选中状态
  store.commit('main/setSelectedKeys', [to.name])
  // 顶部tab页
  if (to.path) {
    store.dispatch('main/addTab', to.name)
  }
  store.commit('main/setSelectedTab', to.name)
})

function findValueInTree(tree, value, account) {
  if(value === "home"||value === "login"|| account==="admin"){
    return true
  }
  // 遍历数组中的每个对象
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    // 检查当前节点是否包含目标值
    if (node.menuurl === value) {
      return true; // 找到目标值，返回 true
    }

    // 如果当前节点有子节点，则递归遍历子节点
    if (node.children && node.children.length > 0) {
      if (findValueInTree(node.children, value)) {
        return true; // 在子节点中找到目标值，返回 true
      }
    }
  }
  // 未找到目标值，返回 false
  return false;
}

export default router
