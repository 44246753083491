/*
 * @Author: zhaoxin
 * @Date: 2020-12-26 13:47:04
 * @Description: websocket
 */
import store from '@/store'

export default class WS {
  constructor() {
    this.ws = null;
  }
  connect(callback) {
    // console.log('====================connect')
    if(this.ws) {
      this.disconnect();
    }
    if(process.env.NODE_ENV == 'development') {
      this.ws = new WebSocket('ws://192.168.2.10:8080/websocket');
      // this.ws = new WebSocket('ws://pmc.u-lake.com/websocket');
    }else {
      let host = location.host;
      let surl = 'wss://'+host+'/websocket';
      this.ws = new WebSocket(surl);
    }
    this.ws.onopen = (evt) => {
      console.log('WebSocket open')
      // this.ws.send('{"devicenum":"001"}')
      callback(this.ws)
    }
    this.ws.onmessage = function(evt) {
      // console.log('WebSocket message', evt.data)
      try {
        let message = JSON.parse(evt.data);
        // console.log(message)
        if(message) {
          store.commit('setSocketMessage', message)
        }
      }catch(e) {}
    }
  }
  disconnect() {
    console.log('websocket disconnect')
    try {
      this.ws.close();
      this.ws = null;
    }catch(e) {}
  }
}