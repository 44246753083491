import menu from '@/json/menu1'

export default [
  {
    url: "/mock/login",
    response: {
      "returncode": "0",
      "errormsg": "登录成功",
      'token': 'token',
      "userid": 1,
      "useraccount": "admin",
      "username": "张三",
      "menu": menu
    }
  },
  {
    url: "/mock/logout",
    response: {
      "returncode": "0",
      "errormsg": "",
    }
  }
];
