import Vue from 'vue'
import Vuex from 'vuex'
import { setCache, removeCache, filterAsideMenu } from 'U'
import main from './modules/main'
import cache from './modules/cache'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId:'',
    mock: false,
    token: '',
    userInfo: null,
    // 需要缓存的页面名称集合（注意：includes指令中配置的名称是组件内部name定义的名称，不是路由中配置的name）
    keepAliveComponents: [],
    // 页面参数缓存,防止页面刷新参数丢失
    paramsMap: {},
    loading: false,
    menuList: [],
    socketMessage: null,
    pageScrollInfo: {
      top: 0,
      left: 0,
    }
  },
  getters: {
    // getSocketMessage:state => state.socketMessage,
    getUserId: state => state.userId,
    asideMenuTree: state => {
      let menuList = JSON.parse(JSON.stringify(state.menuList))
      return filterAsideMenu(menuList)
    },
    uploadHeaders: state => {
      return {
        Authorization: state.token
      }
    },
    uploadData: () => {
      return {
        folder: 'pc',
      }
    },
  },
  mutations: {
    setUserId(state,userId){
      state.userId = userId;
    },
    setCacheDatas(state, cacheDatas) {
      // 恢复缓存数据token、userInfo等
      if(cacheDatas) {
        for(let key in cacheDatas) {
          state[key] = cacheDatas[key]
        }
      }
    },
    setToken(state, token) {
      if(token) {
        state.token = token;
        setCache('token', token);
      }else {
        state.token = '';
        removeCache('token');
      }
    },
    setUserInfo: (state, userInfo) => {
      if (userInfo) {
        state.userInfo = userInfo;
        setCache("userInfo", userInfo);
      } else {
        state.userInfo = null;
        removeCache("userInfo");
      }
    },
    addKeepAlive(state, name) {
      let arr = state.keepAliveComponents.map(item => {
        return item;
      });
      arr.push(name);
      state.keepAliveComponents = arr;
    },
    delKeepAlive(state, name) {
      let arr = state.keepAliveComponents.filter(item => {
        return item != name;
      })
      state.keepAliveComponents = arr;
    },
    setPageParams: (state, payload) => {
      state.paramsMap[payload.path] = payload.params;
      setCache("paramsMap", state.paramsMap);
    },
    setParamsMap: (state, paramsMap) => {
      state.paramsMap = paramsMap;
      setCache("paramsMap", paramsMap);
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setMenuList(state, menuList) {
      state.menuList = menuList;
      setCache("menuList", menuList);
    },
    setSocketMessage(state, socketMessage) {
      state.socketMessage = socketMessage;
    },
    setPageScrollInfo(state, pageScrollInfo) {
      state.pageScrollInfo = pageScrollInfo;
    }
  },
  actions: {
  },
  modules: {
    main,
    cache,
  }
})
