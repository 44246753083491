export default [
  // 维保单位管理-列表查询
  {
    url: "/mock/userdep/getUserdepListByCondition",
    response: {
      "item|5": [
        {
          "userdepid|+1": 8,
          "userdepcode": "04",
          "userdepname": "物业单位8",
          "deptype": 1,
          "parentid": null,
          "abbreviation": "物业单位8",
          "levelid": "couper",
          "sequenceno": 1,
          "address": "南京市鼓楼区",
          "areacode": "0000",
          "telphone": "13675194727",
          "mailcode": "0258888",
          "rescuepreson": "应急人员1",
          "rescuephone": "13467863526",
          "maintenanceperson1": "维护人员1",
          "maintenancephone1": "13467863526",
          "maintenanceperson2": "维保人员2",
          "maintenancephone2": "13756289762",
          "safeperson": "安全人员1",
          "safephone": "13687294589",
          "areamanager": "经理1",
          "areamanagerphone": "13765284567",
          "depmanager": "单位经理1",
          "depmanagerphone": "13675184727",
          "legalperson": "法人1",
          "legalphone": "13675184727",
          "legalid": "340824198311116430",
          "coordinatex": "222222",
          "coordinatey": "444444",
          "certificatenum": "334444444",
          "depnum": "55555",
          "businessnum": "7836633",
          "licensenum": "34445",
          "certificatepic": "/url/image.jpg",
          "deppic": "/url/image.jpg",
          "businesspic": "/url/image.jpg",
          "licensepic": "/url/image.jpg",
          "liftpropertyid": 3,
          "userdepdesc": "描述",
          "createtime": "20201024180130",
          "updatetime": null,
          "childsize": 0
        }
      ],
      "returncode": "0",
      "count": 1,
      "errormsg": "ok"
    }
  },
  // 维保单位管理-根据父级id查询
  {
    url: "/mock/userdep/getChildTreeListByParentId",
    response: {
      "item": [
        {
          "userdepid": 9,
          "userdepcode": "0400",
          "userdepname": "物业单位12",
          "deptype": 1,
          "parentid": "8",
          "abbreviation": "物业单位12",
          "levelid": "couper",
          "sequenceno": 1,
          "address": "南京市鼓楼区",
          "areacode": "0000",
          "telphone": "13675194727",
          "mailcode": "0258888",
          "rescuepreson": "应急人员1",
          "rescuephone": "13467863526",
          "maintenanceperson1": "维护人员1",
          "maintenancephone1": "13467863526",
          "maintenanceperson2": "维保人员2",
          "maintenancephone2": "13756289762",
          "safeperson": "安全人员1",
          "safephone": "13687294589",
          "areamanager": "经理1",
          "areamanagerphone": "13765284567",
          "depmanager": "单位经理1",
          "depmanagerphone": "13675184727",
          "legalperson": "法人1",
          "legalphone": "13675184727",
          "legalid": "340824198311116430",
          "coordinatex": "222222",
          "coordinatey": "444444",
          "certificatenum": "334444444",
          "depnum": "55555",
          "businessnum": "7836633",
          "licensenum": "34445",
          "certificatepic": "/url/image.jpg",
          "deppic": "/url/image.jpg",
          "businesspic": "/url/image.jpg",
          "licensepic": "/url/image.jpg",
          "liftpropertyid": 3,
          "userdepdesc": "描述",
          "createtime": "20201024180157",
          "updatetime": null,
          "childsize": 0//表示子单位数量
        }
      ],
      "returncode": "0",
      "errormsg": "ok"
    }
  },
  // 维保单位管理-删除
  {
    url: "/mock/userdep/deleteUserdep",
    response: {
      "returncode|+1": ["0", "1"],
      "errormsg|+1": ["删除成功", "删除失败"]
    }
  },
  // 维保单位管理-新增
  {
    url: "/mock/userdep/addUserdep",
    response: {
      "returncode|+1": ["0", "1"],
      "errormsg|+1": ["新增成功", "新增失败"]
    }
  },
  // 维保单位管理-修改
  {
    url: "/mock/userdep/modifyUserdep",
    response: {
      "returncode|+1": ["0", "1"],
      "errormsg|+1": ["修改成功", "修改失败"]
    }
  },
];
