export default [
  // 获取参数值
  {
    url: "/mock/device/getDeviceparamValueList",
    response: {
      "schema": {
        "image": "图像",
        "iSharpness": "锐度",
        "iSaturation": "饱和度",
        "id": "id",
        "iBrightness": "亮度",
        "iContrast": "对比度"
      },
      "item": [
        {
          "modeltype": "1",
          "modelnum": "340200000013200001021",
          "params": {
            "methord": "GET",
            "body": {
              "iSharpness": "0",
              "host": {
                ip: "47.96.97.55",
                password: "public",
                port: 1886,
                tls: 0,
                user: "admin"
              },
              "iSaturation": "0",
              "id": "0",
              "iBrightness": "0",
              "iContrast": "0",
            },
            "url": "/audio/0"
          }
        }
      ],
      "returnmsg": "OK",
      "returncode": "0"
    }
  },
];
