import moment from 'moment'
import JSEncrypt from 'jsencrypt'
// 数据本地缓存
export function setCache(key, data, isLocalStorage) {
  key = "elevator" + key;
  let storage = isLocalStorage ? localStorage : sessionStorage;
  let dataType = typeof data;
  if (dataType == "string") {
    storage.setItem(key, "string" + data);
  } else {
    storage.setItem(key, JSON.stringify(data));
  }
}
// 获取缓存数据
export function getCache(key, isLocalStorage) {
  key = "elevator" + key;
  let storage = isLocalStorage ? localStorage : sessionStorage;
  let data = storage.getItem(key);
  if (data) {
    if (data.indexOf("string") === 0) {
      data = data.replace("string", "");
      return data;
    } else {
      return JSON.parse(data);
    }
  } else {
    return null;
  }
}
// 移除本地缓存
export function removeCache(key, isLocalStorage) {
  key = "elevator" + key;
  let storage = isLocalStorage ? localStorage : sessionStorage;
  storage.removeItem(key);
}
// 获取所有缓存数据，以json格式返回
export function getAllCache(isLocalStorage) {
  let result = {};
  let storage = isLocalStorage ? localStorage : sessionStorage;
  for(let key in storage) {
    if(storage.hasOwnProperty(key)) {
      key = key.replace('elevator', '');
      result[key] = getCache(key, isLocalStorage);
    }
  }
  return result;
}
/**
 * @description: 遍历数组，查找字段key值等于value的元素
 * @param：arr: 要遍历的数组 key:匹配的key value:匹配值 children:子集的key 
 * @return String 
 */
export function getItemFromArrayByKey(arr, key, value, children='children') {
  let result = null;
  if(value) {
    for(let i=0; i<arr.length; i++) {
      let item = arr[i];
      if(item[key] == value) {
        result = item;
        break;
      }
      if(item[children] && item[children].length) {
        result = getItemFromArrayByKey(item[children], key, value, children);
        if(result) {
          break;
        }
      }
    }
  }
  return result;
}
/**
 * @description: 遍历数组，筛选字段key值等于value的元素
 * @param：arr: 要遍历的数组 key:匹配的key value:匹配值 children:子集的key 
 * @return String 
 */
export function filterTreeByKey(arr, key, value, children='children') {
  let result = null;
  if(arr && arr.length) {
    result = [];
    arr.forEach(item => {
      if(item[key] == value) {
        if(item[children]) {
          item[children] = filterTreeByKey(item[children], key, value, children)
        }
        result.push(item);
      }
    })
  }
  return result;
}
/**
 * @description: 对象转数组
 * @param：obj: 对象
 * @return arr 
 */
export function objectToArray(obj, nameMap) {
  let result = [];
  if(obj) {
    for(let key in obj) {
      let item = {
        key: key,
        value: obj[key],
      };
      if(nameMap) {
        item.name = nameMap[key] || key;
      }
      if(obj[key] && typeof obj[key] == 'object') {
        item.children = objectToArray(obj[key], nameMap);
      }
      result.push(item)
    }
  }
  return result;
}
/**
 * @description: 数组转对象
 * @param：arr: 对象
 * @return obj 
 */
export function arrayToObject(arr) {
  let result = {};
  if(arr) {
    arr.forEach(item => {
      if(item.children) {
        result[item.key] = arrayToObject(item.children);
      }else {
        result[item.key] = item.value;
      }
    })
  }
  return result;
}
/**
 * @description: 获取随机字符串
 * @param {Number: 字符串长度} 
 * @return {String: 随机字符串} 
 */
export function getRandomString(length) {
  let nums = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  let result = [];
  for(let i=0; i<length; i++) {
    result.push(nums[Math.floor(Math.random() * nums.length)]);
  }
  return result.join('');
}
// 获取省份列表
export function getProvinceList(arr) {
  return arr.map(item => {
    return {
      id: item.id,
      value: item.value
    }
  })
}
// 获取城市列表
export function getCityList(arr, province) {
  let cityList = [];
  for(let i=0; i<arr.length; i++) {
    if(arr[i].id == province) {
      cityList = arr[i].children.map(item => {
        return {
          id: item.id,
          value: item.value
        }
      })
      break;
    }
  }
  return cityList;
}
// 获取区域列表
export function getAreaList(arr, city) {
  let areaList = [];
  let cityItem = getItemFromArrayByKey(arr, 'id', city);
  if(cityItem) {
    areaList = cityItem.children.map(item => {
      return {
        id: item.id,
        value: item.value
      }
    })
  }
  return areaList;
}
// 过滤左菜单（屏蔽app菜单apptype==2和操作菜单menutype==2）
export function filterAsideMenu(arr) {
  let result = [];
  arr.forEach(item => {
    if(item.apptype != 2 && item.menutype != 2) {
      if(item.children && item.children.length) {
        item.children = filterAsideMenu(item.children)
      }
      result.push(item);
    }
  })
  return result;
}
// 递归查询某字段，拼接成字符串
export function getTreeDeepString(obj, result, key, child) {
  if(obj && obj[key]) {
    if(result) {
      result += '|' + obj[key];
    }else {
      result = '' + obj[key];
    }
    if(obj[child]) {
      result = getTreeDeepString(obj[child], result, key, child)
    }
  }
  return result;
}
// 根据timerange数组获取开始和结束时间
export function getTimeRange(arr, formater='YYYYMMDD') {
  let result = {
    start: '',
    end: ''
  }
  if(arr && arr.length) {
    result.start = moment(arr[0]).format(formater);
    if(arr.length == 2) {
      result.end = moment(arr[1]).format(formater);
    }else {
      result.end = moment(arr[0]).format(formater);
    }
  }
  return result;
}

export function getRsaMessage(message, publicKey) {
  let enc = new JSEncrypt();
  enc.setPublicKey(publicKey);
  return enc.encrypt(message);
}