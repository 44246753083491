import Vue from 'vue'
import store from '@/store'

Vue.mixin({
  mounted() {
    this.$nextTick(() => {
      if(this.$options.name && this.$route && this.$route.meta.keepAlive && store.state.keepAliveComponents.indexOf(this.$route.name)<0) {
        store.commit('addKeepAlive', this.$route.name)
      }
    })
  }
})